import { Box, Link, Typography } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import styled from '@emotion/styled';
import { useWatch } from 'react-hook-form';
import useCalculateAnnuity from '../hooks/useCalculateAnnuity';
import selectClient from '../hooks/selectClient';

const Annuity = ({ control, defaultValues }) => {
  const { strapiKorak1, registry } = useStaticQuery(query);
  const [amount, repaymentPeriod] = useWatch({
    control,
    name: ['amount', 'repaymentPeriod'],
    defaultValues,
  });
  const annuity = useCalculateAnnuity(control, registry.data.creditMatrix, defaultValues);

  return (
    <Box textAlign="center" my={1}>
      <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
        <Typography variant="h6" color="primary" sx={{ mr: 1 }}>
          Mjesečna rata kredita:
        </Typography>
        <Typography variant="h4" color="primary">
          {annuity} KM
        </Typography>
      </Box>

      {selectClient() === 'Partner' ? (
        <StyledTinyText>gmail
          Reprezentativni primjer mikrokredita: Iznos 1000 KM, rok otplate 12 mjeseci, uz nominalnu
          kamatnu stopu 19.99%, efektivnu kamatnu stopu <strong>21.97%</strong>, bez naknada za obradu, bez troška
          mjenice, i bez troška ovjere dokumentacije. Iskazane vrijednosti su informativnog karaktera. Aktuelni proizvod za
          koji aplicirate je{' '}
          <Link href={strapiKorak1.anuitetInformativniTekst.fajl.localFile.publicURL} target="_blank">
            {strapiKorak1.anuitetInformativniTekst.url}
          </Link>
          .
        </StyledTinyText>
      ) : (
        <StyledTinyText>
          Reprezentativni primjer mikrokredita: Iznos 11000 KM, rok otplate 24 mjeseci, uz nominalnu
          kamatnu stopu 19.99%, efektivnu kamatnu stopu <strong>22.07%</strong>, bez naknada za obradu, sa troškom
          mjenice, kao i troškom ovjere dokumentacije. Iskazane vrijednosti su informativnog karaktera. Aktuelni proizvod za 
          koji aplicirate je Partner KLIK mikrokredit.
        </StyledTinyText>
      )}

    </Box>
  );
};

const StyledTinyText = styled(Typography)`
  font-size: 10px;
  line-height: 17px;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-align: justify;
`;

const query = graphql`
  query AnnuityMobileQuery {
    registry {
      data {
        creditMatrix {
          interest
          installmentsMax
          installmentsMin
          amountFrom
          amountTo
        }
      }
    }
    strapiKorak1 {
      anuitetInformativniTekst {
        id
        naslov
        url
        fajl {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

export default Annuity;
