import { useWatch } from 'react-hook-form';
import selectClient from '../hooks/selectClient';

const useCalculateAnnuity = (control, creditMatrix, defaultValues) => {
  const [amount, repaymentPeriod] = useWatch({
    control,
    name: ['amount', 'repaymentPeriod'],
    defaultValues,
  });

  const sortedByScore = creditMatrix
    ?.map((option) => ({
      score:
        Number(option.amountFrom <= amount) +
        Number(amount <= option.amountTo) +
        Number(option.installmentsMin <= repaymentPeriod) +
        Number(repaymentPeriod <= option.installmentsMax),
      ...option,
    }))
    .sort((a, b) => b.score - a.score);

  //!!!!!hardkodirano rješenje - treba popričati sa partnerom zašto je kamata 0
  const interest = selectClient() == 'Partner' ? 19.99 : 19.99;

  // annuity
  return Math.round(
    (((amount * (interest / 12)) / 100) * (1 + interest / 12 / 100) ** repaymentPeriod) /
      ((1 + interest / 12 / 100) ** repaymentPeriod - 1),
    1
  );
};

export default useCalculateAnnuity;
